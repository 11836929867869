/**
 * @generated SignedSource<<087ef2a56a9d5f5c1f9e5591421c20da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterDimensions_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_user">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterDimensions_user";
};
export type SbMobileRefineMenuFilterDimensions_user$key = {
  readonly " $data"?: SbMobileRefineMenuFilterDimensions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterDimensions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterDimensions_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRangeDimensions_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dec7a359e1cea4a5ba3701159e26bdf9";

export default node;
