import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    shouldShowBackToAllCategories,
    getPreviouslyAppliedCategory,
    getBackToCategory,
    getCurrentCategory,
} from '../../../utils/categoryHelpers';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { useIntl, defineMessages } from 'dibs-react-intl';
import { updateUriRef } from '../../../actions/filterActions';

// components
import { SbSharedRefineMenuLink } from '../../SbSharedRefineMenu/SbSharedRefineMenuLink/SbSharedRefineMenuLink';
import { SbSharedRefineMenuRadioSelectList } from '../../SbSharedRefineMenu/SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectList';

const { backTo, backToAllCategories } = defineMessages({
    backTo: {
        id: 'sb.SbMobileRefineMenuFilterCategory.backTo',
        defaultMessage: `Back To All {backToCategory}`,
    },
    backToAllCategories: {
        id: 'sb.SbMobileRefineMenuFilterCategory.backToAll',
        defaultMessage: 'Back To All Categories',
    },
});

export const SbMobileRefineMenuFilterCategoryComponent = props => {
    const { itemSearch, filters, onClick = () => {} } = props;
    const { appliedFilters, pageType, isAttributePage } = itemSearch;
    const intl = useIntl();
    const previouslyAppliedCategory = getPreviouslyAppliedCategory(appliedFilters);
    const backToCategory = getBackToCategory(appliedFilters);
    const currentCategory = getCurrentCategory(appliedFilters, filters);
    const showBackToAllCategories = shouldShowBackToAllCategories(
        { pageType, isAttributePage },
        filters
    );

    const { name, totalCount } = currentCategory;
    let filterValues;

    /**
     * If the last available filter is currently applied only show
     * the selected value and don't render anchor link for it.
     * The user can use the back button to undo the filter
     * and see the full list of options.
     */
    if (!!previouslyAppliedCategory && previouslyAppliedCategory.name === name) {
        filterValues = [{ ...currentCategory?.values?.[0], linkable: false }];
    } else {
        filterValues = currentCategory.values;
    }

    const { linkReference, linkable } = previouslyAppliedCategory?.values?.[0] || {};
    const sharedProps = {
        onClick: event =>
            onClick(previouslyAppliedCategory?.name, previouslyAppliedCategory?.values?.[0], event),
        linkReference,
        linkable,
    };

    return (
        <Fragment>
            {!backToCategory && showBackToAllCategories && previouslyAppliedCategory && (
                <SbSharedRefineMenuLink
                    {...sharedProps}
                    text={intl.formatMessage(backToAllCategories)}
                />
            )}
            {!!backToCategory && (
                <SbSharedRefineMenuLink
                    {...sharedProps}
                    text={intl.formatMessage(backTo, {
                        backToCategory: backToCategory?.values?.[0]?.displayName,
                    })}
                />
            )}
            <SbSharedRefineMenuRadioSelectList
                values={filterValues}
                filterName={name}
                totalCount={totalCount}
                itemSearch={itemSearch}
                allLabel={previouslyAppliedCategory?.values[0]?.displayName}
            />
        </Fragment>
    );
};

SbMobileRefineMenuFilterCategoryComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        onClick(filterName, filterValue, event) {
            dispatch(updateUriRef({ filterName, filterValue, isDeselected: true, event }));
        },
    };
}

export const SbMobileRefineMenuFilterCategory = createFragmentContainer(
    connect(null, mapDispatchToProps)(SbMobileRefineMenuFilterCategoryComponent),
    {
        itemSearch: graphql`
            fragment SbMobileRefineMenuFilterCategory_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectList_itemSearch
                isAttributePage
                appliedFilters {
                    name
                    values {
                        linkReference
                        urlLabel
                        displayName
                    }
                }
                pageType
            }
        `,
        filters: graphql`
            fragment SbMobileRefineMenuFilterCategory_filters on SearchBrowseFilter
            @relay(plural: true) {
                name
                totalCount
                values {
                    count
                    linkReference
                    urlLabel
                    displayName
                    linkable
                }
            }
        `,
    }
);
