/**
 * @generated SignedSource<<06514b051f7c5a05745d97780538c2d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterPrice_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeCurrency_itemSearch" | "SbSharedRefineMenuNetPriceTradeDiscount_itemSearch" | "SbSharedRefineMenuPriceRanges_itemSearch" | "SbSharedRefineMenuSaleInput_itemSearch">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterPrice_itemSearch";
};
export type SbMobileRefineMenuFilterPrice_itemSearch$key = {
  readonly " $data"?: SbMobileRefineMenuFilterPrice_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterPrice_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterPrice_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRangeCurrency_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuPriceRanges_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuSaleInput_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuNetPriceTradeDiscount_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "4f0004b3672b13a50359d36977e23155";

export default node;
