import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectSearch } from '../../SbSharedRefineMenu/SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectSearch';

export const SbMobileRefineMenuFilterStoneCutComponent = props => (
    <SbSharedRefineMenuMultiSelectSearch {...props} />
);

export const SbMobileRefineMenuFilterStoneCut = createFragmentContainer(
    SbMobileRefineMenuFilterStoneCutComponent,
    {
        viewer: graphql`
            fragment SbMobileRefineMenuFilterStoneCut_viewer on Viewer {
                ...SbSharedRefineMenuMultiSelectSearch_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbMobileRefineMenuFilterStoneCut_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectSearch_itemSearch
            }
        `,
    }
);
