import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';

// components
import { SbSharedRefineMenuInputRangeCurrency } from '../../SbSharedRefineMenu/SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRangeCurrency';
import { SbSharedRefineMenuPriceRanges } from '../../SbSharedRefineMenu/SbSharedRefineMenuPriceRanges/SbSharedRefineMenuPriceRanges';
import { SbSharedRefineMenuSaleInput } from '../../SbSharedRefineMenu/SbSharedRefineMenuSaleInput/SbSharedRefineMenuSaleInput';
import { SbSharedRefineMenuNetPriceTradeDiscount } from '../../SbSharedRefineMenu/SbSharedRefineMenuNetPriceTradeDiscount/SbSharedRefineMenuNetPriceTradeDiscount';

import { type SbMobileRefineMenuFilterPrice_user$key } from './__generated__/SbMobileRefineMenuFilterPrice_user.graphql';
import { type SbMobileRefineMenuFilterPrice_itemSearch$key } from './__generated__/SbMobileRefineMenuFilterPrice_itemSearch.graphql';

type Props = {
    currency: string;
    user: SbMobileRefineMenuFilterPrice_user$key;
    itemSearch: SbMobileRefineMenuFilterPrice_itemSearch$key;
};

export const SbMobileRefineMenuFilterPrice: FC<Props> = props => {
    const { user: userRef, itemSearch: itemSearchRef } = props;

    const user = useFragment(
        graphql`
            fragment SbMobileRefineMenuFilterPrice_user on User {
                ...SbSharedRefineMenuPriceRanges_user
            }
        `,
        userRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SbMobileRefineMenuFilterPrice_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRangeCurrency_itemSearch
                ...SbSharedRefineMenuPriceRanges_itemSearch
                ...SbSharedRefineMenuSaleInput_itemSearch
                ...SbSharedRefineMenuNetPriceTradeDiscount_itemSearch
            }
        `,
        itemSearchRef
    );

    return (
        <>
            <SbSharedRefineMenuPriceRanges {...props} user={user} itemSearch={itemSearch} />
            <SbSharedRefineMenuInputRangeCurrency {...props} itemSearch={itemSearch} applyOnBlur />
            <SbSharedRefineMenuNetPriceTradeDiscount itemSearch={itemSearch} />
            <SbSharedRefineMenuSaleInput {...props} itemSearch={itemSearch} />
        </>
    );
};
