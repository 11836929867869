import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuCaratWeight } from '../../SbSharedRefineMenu/SbSharedRefineMenuCaratWeight/SbSharedRefineMenuCaratWeight';

export const SbMobileRefineMenuFilterCaratWeightComponent = props => (
    <SbSharedRefineMenuCaratWeight {...props} applyOnBlur />
);

export const SbMobileRefineMenuFilterCaratWeight = createFragmentContainer(
    SbMobileRefineMenuFilterCaratWeightComponent,
    {
        itemSearch: graphql`
            fragment SbMobileRefineMenuFilterCaratWeight_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuCaratWeight_itemSearch
            }
        `,
    }
);
