import { defineMessages } from 'dibs-react-intl';

// components
import { SbMobileRefineMenuFilterPrice } from './SbMobileRefineMenuFilterPrice';
import { SbMobileRefineMenuFilterStoneCut } from './SbMobileRefineMenuFilterStoneCut';
import { SbMobileRefineMenuFilterCategory } from './SbMobileRefineMenuFilterCategory';
import { SbMobileRefineMenuFilterDimensions } from './SbMobileRefineMenuFilterDimensions';
import { SbMobileRefineMenuFilterCaratWeight } from './SbMobileRefineMenuFilterCaratWeight';
import { SbMobileRefineMenuFilterArtSize } from './SbMobileRefineMenuFilterArtSize';
import { SbSharedRefineMenuFilterItemLocation } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterItemLocation';
import { SbSharedRefineMenuFilterShipsFrom } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterShipsFrom';
import { SbSharedRefineMenuFilterDeliveryOptions } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterDeliveryOptions';
import { sbSharedRefineMenuFiltersMap } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/sbSharedRefineMenuFiltersMap';

import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';

const titleOverrides = defineMessages({
    dimensions: {
        id: 'sb.SbMobileRefineMenuFilterDimension.title',
        defaultMessage: 'Dimensions',
    },
    shippingOptions: {
        id: 'sb.SbMobileRefineMenuFilterDeliveryOptions.title',
        defaultMessage: 'Shipping Options',
    },
    itemLocation: {
        id: 'sb.SbMobileRefineMenuFilterItemLocation.title',
        defaultMessage: 'Item Location',
    },
    shipsFrom: {
        id: 'sb.SbRespRefineMenuFilterItemLocation.ShipsFrom.title',
        defaultMessage: 'Ships From',
    },
});

export const sbMobileRefineMenuFiltersMap = Object.assign({}, sbSharedRefineMenuFiltersMap, {
    'carat-weight': {
        component: SbMobileRefineMenuFilterCaratWeight,
    },
    categoryL1: {
        component: SbMobileRefineMenuFilterCategory,
    },
    'art-size': {
        component: SbMobileRefineMenuFilterArtSize,
    },
    height: {
        component: SbMobileRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    width: {
        component: SbMobileRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    length: {
        component: SbMobileRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    depth: {
        component: SbMobileRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    price: {
        component: SbMobileRefineMenuFilterPrice,
    },
    priceTrade: {
        component: SbMobileRefineMenuFilterPrice,
    },
    'stone-cut': {
        component: SbMobileRefineMenuFilterStoneCut,
    },
    shippingOptions: {
        component: SbSharedRefineMenuFilterDeliveryOptions,
        title: titleOverrides.shippingOptions,
    },
    location: {
        component: SbSharedRefineMenuFilterItemLocation,
        title: titleOverrides.itemLocation,
    },
    gallery: {
        component: null,
    },
    latest: {
        component: null,
        isBatchAppliedFilter: true,
    },
    sale: {
        component: null,
        isBatchAppliedFilter: true,
    },
    customizable: {
        component: null,
        isBatchAppliedFilter: true,
    },
    'net-price': {
        component: null,
        isBatchAppliedFilter: true,
    },
    returnable: {
        component: null,
        isBatchAppliedFilter: true,
    },
    recognized: {
        component: null,
        isBatchAppliedFilter: true,
    },
    topSeller: {
        component: null,
        isBatchAppliedFilter: true,
    },
    isSellerStrategicPartner: {
        component: null,
        isBatchAppliedFilter: true,
    },
});

export const useSbMobileRefineMenuFiltersMap = () => {
    const { isItemLocationFilterVariant } = useServerVarsContext();
    return {
        ...sbMobileRefineMenuFiltersMap,
        location: isItemLocationFilterVariant
            ? {
                  component: SbSharedRefineMenuFilterShipsFrom,
                  title: titleOverrides.shipsFrom,
              }
            : {
                  component: SbSharedRefineMenuFilterItemLocation,
                  title: titleOverrides.itemLocation,
              },
    };
};

export const isBatchAppliedFilter = filterName =>
    sbMobileRefineMenuFiltersMap[filterName] &&
    !!sbMobileRefineMenuFiltersMap[filterName].isBatchAppliedFilter;
