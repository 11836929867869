import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuInputRangeDimensions } from '../../SbSharedRefineMenu/SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRangeDimensions';

export const SbMobileRefineMenuFilterDimensionsComponent = props => (
    <SbSharedRefineMenuInputRangeDimensions {...props} applyOnBlur />
);

export const SbMobileRefineMenuFilterDimensions = createFragmentContainer(
    SbMobileRefineMenuFilterDimensionsComponent,
    {
        itemSearch: graphql`
            fragment SbMobileRefineMenuFilterDimensions_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRangeDimensions_itemSearch
            }
        `,
        filters: graphql`
            fragment SbMobileRefineMenuFilterDimensions_filters on SearchBrowseFilter
            @relay(plural: true) {
                ...SbSharedRefineMenuInputRangeDimensions_filters
            }
        `,
        user: graphql`
            fragment SbMobileRefineMenuFilterDimensions_user on User {
                ...SbSharedRefineMenuInputRangeDimensions_user
            }
        `,
    }
);
