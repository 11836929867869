/**
 * @generated SignedSource<<8ab7460f4ff6deb037f958ea0eaa84bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterArtSize_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtSize_user">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterArtSize_user";
};
export type SbMobileRefineMenuFilterArtSize_user$key = {
  readonly " $data"?: SbMobileRefineMenuFilterArtSize_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterArtSize_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterArtSize_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtSize_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7e9c248a66e9da2e059f61ef2fd80cea";

export default node;
