/**
 * @generated SignedSource<<33d381422abbf51c7eeed8638cf5eef2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterArtSize_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtSize_filters">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterArtSize_filters";
}>;
export type SbMobileRefineMenuFilterArtSize_filters$key = ReadonlyArray<{
  readonly " $data"?: SbMobileRefineMenuFilterArtSize_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterArtSize_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileRefineMenuFilterArtSize_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtSize_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "ca676ff6f4075b3942eca9264d97d62a";

export default node;
