/**
 * @generated SignedSource<<5a03afec40d5efc8bab4d91284fa321a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterStoneCut_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_viewer">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterStoneCut_viewer";
};
export type SbMobileRefineMenuFilterStoneCut_viewer$key = {
  readonly " $data"?: SbMobileRefineMenuFilterStoneCut_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterStoneCut_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterStoneCut_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "481b8f6741d62541eb7228ebe6a486fa";

export default node;
