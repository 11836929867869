/**
 * @generated SignedSource<<79d93d11233dc10bf9ab39bf6a8bb190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterStoneCut_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_itemSearch">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterStoneCut_itemSearch";
};
export type SbMobileRefineMenuFilterStoneCut_itemSearch$key = {
  readonly " $data"?: SbMobileRefineMenuFilterStoneCut_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterStoneCut_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterStoneCut_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "ab3e8a046c2973f6a8062b2890096ab3";

export default node;
