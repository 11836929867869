/**
 * @generated SignedSource<<7dadffc7f4c040fdc83d8e9db5273895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterCategory_filters$data = ReadonlyArray<{
  readonly name: string | null;
  readonly totalCount: number | null;
  readonly values: ReadonlyArray<{
    readonly count: number | null;
    readonly displayName: string | null;
    readonly linkReference: string | null;
    readonly linkable: boolean | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbMobileRefineMenuFilterCategory_filters";
}>;
export type SbMobileRefineMenuFilterCategory_filters$key = ReadonlyArray<{
  readonly " $data"?: SbMobileRefineMenuFilterCategory_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterCategory_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileRefineMenuFilterCategory_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "ad1fcc4e1738b971a5d1eecf090efb81";

export default node;
