/**
 * @generated SignedSource<<0b80bea653947a1038fd9df0ba523731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuFilterCaratWeight_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuCaratWeight_itemSearch">;
  readonly " $fragmentType": "SbMobileRefineMenuFilterCaratWeight_itemSearch";
};
export type SbMobileRefineMenuFilterCaratWeight_itemSearch$key = {
  readonly " $data"?: SbMobileRefineMenuFilterCaratWeight_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterCaratWeight_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuFilterCaratWeight_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuCaratWeight_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "a9ee523adaec7ff728b7f74349aed89c";

export default node;
