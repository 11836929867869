import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { type SbMobileRefineMenuFilterArtSize_itemSearch$key } from './__generated__/SbMobileRefineMenuFilterArtSize_itemSearch.graphql';
import { type SbMobileRefineMenuFilterArtSize_user$key } from './__generated__/SbMobileRefineMenuFilterArtSize_user.graphql';
import { type SbMobileRefineMenuFilterArtSize_filters$key } from './__generated__/SbMobileRefineMenuFilterArtSize_filters.graphql';
import { type SbSharedRefineMenuFilters_filters$data } from './../../SbSharedRefineMenu/SbSharedRefineMenuFilters/__generated__/SbSharedRefineMenuFilters_filters.graphql';
import { type ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

// components
import { SbSharedRefineMenuFilterArtSize } from './../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterArtSize';

type Props = {
    itemSearch: SbMobileRefineMenuFilterArtSize_itemSearch$key;
    filters: SbMobileRefineMenuFilterArtSize_filters$key;
    user: SbMobileRefineMenuFilterArtSize_user$key;
    filterName: string;
    shouldUseExpandableList: boolean;
    values: ArrayElement<SbSharedRefineMenuFilters_filters$data>['values'];
};

export const SbMobileRefineMenuFilterArtSize: FC<Props> = ({
    user: userRef,
    itemSearch: itemSearchRef,
    filters: filtersRef,
    filterName,
    values,
    shouldUseExpandableList,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbMobileRefineMenuFilterArtSize_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuFilterArtSize_itemSearch
            }
        `,
        itemSearchRef
    );

    const user = useFragment(
        graphql`
            fragment SbMobileRefineMenuFilterArtSize_user on User {
                ...SbSharedRefineMenuFilterArtSize_user
            }
        `,
        userRef
    );

    const filters = useFragment(
        graphql`
            fragment SbMobileRefineMenuFilterArtSize_filters on SearchBrowseFilter
            @relay(plural: true) {
                ...SbSharedRefineMenuFilterArtSize_filters
            }
        `,
        filtersRef
    );

    return (
        <SbSharedRefineMenuFilterArtSize
            itemSearch={itemSearch}
            user={user}
            filters={filters}
            filterName={filterName}
            values={values}
            shouldUseExpandableList={shouldUseExpandableList}
            applyOnBlur
        />
    );
};
